import { OATWrapper } from 'oat-common-ui';
import MainFooter from '../../components/MainFooter';

const Error = () => {
  return (
    <>
      <OATWrapper>
        <h2>Service unavailable. Please try again later.</h2>
      </OATWrapper>
      <MainFooter />
    </>
  );
};

export default Error;
