import { makeAutoObservable } from 'mobx';
import { AdminConstants, APRCalcFns, getDefaultTfsShareByBrand, SubCashCalcFns } from 'oat-admin-common';
import { assignStringValue, uuidv4, validator } from 'oat-common-ui';
import { Brand, VIEW_ALL_RATES } from '../../../../../constants/global';
import { Maybe, Offer, OfferingRate } from '../../../../../gql/generated';
import { aprTiersList } from '../../../offers/AprSection/aprTiersList';
import { initialAprFields, initialOfferFields } from '../../../offers/AprSection/initialData';
import TermWeightedCostModel from '../../../offers/AprSection/models/TermWeightedCostModel';
import TierPenRateModel from '../../../offers/AprSection/models/TierPenRateModel';
import TierTermModel from '../../../offers/AprSection/models/TierTermModel';
import { calcPnvsValues, calculateWeightedTermPnvsValues } from '../../../offers/AprSection/utils/calculations';
import { isComplete, tierTermIsComplete } from '../../../offers/AprSection/utils/isComplete';
import { processRate } from '../../../offers/AprSection/utils/processRate';
import { OfferFields } from '../OfferFields';
import { AprFields, TiersListItem } from './AprFields';

export type DefaultTfsShares = {
  [term: number]: number;
};

const { BRAND_LEXUS } = AdminConstants;

const isTierOnePlus = '1+';
const tiers = [isTierOnePlus, '1', '2', '3'];
const terms = [24, 36, 48, 60, 72];

class AprModel {
  uid = uuidv4();
  offerFields: OfferFields = initialOfferFields;
  aprFields: AprFields = initialAprFields;
  defaultTfsShares: DefaultTfsShares = {};
  selectedTier = isTierOnePlus;
  tiersList: TiersListItem[] = aprTiersList;
  tierPenRatesHasError = this.aprFields.tierPenRates.reduce((acc, curr) => acc + Number(curr.penetrationRate), 0) !== 100;
  termTablePenRatesHasError = this.aprFields.tierTerms.filter(t => t.tier === this.selectedTier).reduce((acc, curr) => acc + Number(curr.penetrationRate), 0) !== 100;
  applyStdRatesDisabled = false;
  isLexus = false;
  ncsRate = 0;

  constructor(rgnlAltId: string, ncsRate: number) {
    makeAutoObservable(this);

    this.offerFields.rgnlAltId = rgnlAltId;
    this.ncsRate = ncsRate ?? 0;
    this.initializeDefaultTfsShares();
  }

  initializeDefaultTfsShares = () => {
    for (const term of terms) {
      this.defaultTfsShares[term] = getDefaultTfsShareByBrand(this.offerFields.optionType, !this.isLexus ? Brand.TOYOTA : Brand.LEXUS, term);
    }
  };

  initializeListData = (isLexus: boolean, standardRates: OfferingRate[]) => {
    this.isLexus = isLexus;

    for (const term of terms) {
      const weightedCostModel = new TermWeightedCostModel();
      weightedCostModel.term = term;
      this.aprFields.termWeightedCosts.push(weightedCostModel);

      for (const tier of tiers) {
        const tierTermModel = new TierTermModel();
        tierTermModel.tier = tier;
        tierTermModel.term = term;
        tierTermModel.tfsShare = this.defaultTfsShares[tierTermModel.term];
        tierTermModel.standardRate = standardRates?.find(sr => sr.tier === tier && sr.term === term)?.rate ?? 0;
        tierTermModel.initialStandardRate = standardRates?.find(sr => sr.tier === tier && sr.term === term)?.rate ?? 0;
        this.aprFields.tierTerms.push(tierTermModel);

        if (this.aprFields.tierPenRates.length < tiers.length) {
          const tierPenRateModel = new TierPenRateModel();
          tierPenRateModel.tier = tier;
          this.aprFields.tierPenRates.push(tierPenRateModel);
        }
      }
    }
  };

  initializeApr = () => {
    this.calcTotalPnvs();
    this.processTierTermRates();
  };

  setRevAndId = ({ id, rev }: Offer) => {
    this.offerFields.id = id;
    this.offerFields.rev = rev;
  };

  applyStandardRates = () => {
    for (const tierTerm of this.aprFields.tierTerms) {
      tierTerm.rate = tierTerm.standardRate;
    }
  };

  updateCostShareId = (id: Maybe<string>, value: number, standardRates: OfferingRate[]) => {
    for (const tierTerm of this.aprFields.tierTerms) {
      const standardRate = standardRates.find(sr => sr.tier === tierTerm.tier && sr.term === tierTerm.term)?.rate ?? 0;
      tierTerm.standardRate = standardRate - value;
    }

    this.offerFields.costShareId = id;
    this.offerFields.isCostShareUpdated = false;

    this.recalculateAll();
  };

  setData = (data: Offer) => {
    const { aprDetails } = data;

    if (aprDetails) {
      this.aprFields = {
        offerPnvs: aprDetails.offerPnvs,
        offerTfsPnvs: aprDetails.offerTfsPnvs,
        offerEnhancedTfsPnvs: aprDetails.offerEnhancedTfsPnvs,
        isNcsApplied: aprDetails.isNcsApplied,
        tierTerms: aprDetails.tierTerms.map(t => new TierTermModel(t)),
        tierPenRates: aprDetails.tierPenRates.map(t => new TierPenRateModel(t)),
        termWeightedCosts: aprDetails.termWeightedCosts.map(t => new TermWeightedCostModel(t)),
        subCashAmount: aprDetails.subCashAmount,
        subCashCostShare: aprDetails.subCashCostShare,
        subCashCostShareCap: aprDetails.subCashCostShareCap,
        subCashPnvs: aprDetails.subCashPnvs,
        subCashTfsPnvs: aprDetails.subCashTfsPnvs,
        subCashEnhancedTfsPnvs: aprDetails.subCashEnhancedTfsPnvs,
      };
    }

    this.isLexus = data.brand === BRAND_LEXUS;
    this.offerFields = { ...data, penetrationRate: assignStringValue(data.penetrationRate) };
    this.offerFields.isEnhTfsCostShareForRegions = aprDetails?.isEnhTfsCostShareForRegions;
    this.offerFields.isEnhSubCashTfsCostShareForRegions = aprDetails?.isEnhSubCashTfsCostShareForRegions;
    this.offerFields.costShareId = aprDetails?.costShareId;
    this.offerFields.isCostShareUpdated = Boolean(aprDetails?.isCostShareUpdated);

    this.tierPenRatesHasError = this.calcTotalPenRate() !== 100;
    this.termTablePenRatesHasError = this.calcTierTermPenRate(this.selectedTier) !== 100;
    this.processTermTableErrors();
    this.initializeApr();
  };

  selectTier = (tier: string) => {
    this.selectedTier = tier;
    this.termTablePenRatesHasError = this.calcTierTermPenRate(tier) !== 100;
  };

  updateOfferField = <T extends keyof OfferFields, V extends OfferFields[T]>(field: T, value: V) => {
    this.offerFields[field] = value;
  };

  updateAprField = <T extends keyof AprFields, V extends AprFields[T]>(field: T, value: V) => {
    this.aprFields[field] = value;

    if (field === 'subCashAmount' || field === 'subCashCostShare' || field === 'subCashCostShareCap') {
      this.calcSubCash();
      this.calcTotalPnvs();
    }
  };

  // For TierTerms
  updateTermField = <T extends keyof TierTermModel, V extends TierTermModel[T]>(tier: string, term: number, field: T, value: V) => {
    const tierTerm = this.aprFields.tierTerms.find((item: TierTermModel) => item.tier === tier && item.term === term);

    if (tierTerm) {
      tierTerm[field] = value;

      switch (field) {
        case 'rate':
          this.updateMonthlyPayment(tierTerm, term);

          if (tier === isTierOnePlus) {
            this.updateRemainingRateFields(term);
            this.updateRemainingMonthlyPayments(term);
          }

          this.processRateErrors(term);
          break;
        case 'penetrationRate':
          this.termTablePenRatesHasError = this.calcTierTermPenRate(tier) !== 100;

          if (tier === isTierOnePlus) {
            this.updateRemainingPenRateFields(term);
          }
          break;
        case 'averageAmountFinanced':
          this.updateMonthlyPayment(tierTerm, term);

          if (tier === isTierOnePlus) {
            this.updateRemainingAvgAmtFields(term);
            this.updateRemainingMonthlyPayments(term);
          }

          break;
        case 'tfsShare':
          if (tier === isTierOnePlus) {
            this.updateRemainingTfsFields(term);
          }

          break;
        default:
          break;
      }

      this.recalculateAll();
    }
  };

  updateMonthlyPayment = (tierTerm: TierTermModel, term: number) => {
    tierTerm.monthlyPayment = APRCalcFns.calculateEstimatedPayment(Number(tierTerm.averageAmountFinanced), Number(tierTerm.rate), term);
  };

  updateRemainingMonthlyPayments = (term: number) => {
    const filteredTerms = this.aprFields.tierTerms.filter((t: TierTermModel) => t.term === term);

    for (const filteredTerm of filteredTerms) {
      const { tier } = filteredTerm;

      if (tier !== isTierOnePlus) {
        filteredTerm.monthlyPayment = APRCalcFns.calculateEstimatedPayment(Number(filteredTerm.averageAmountFinanced), Number(filteredTerm.rate), term);
      }
    }
  };

  updateRemainingAvgAmtFields = (term: number) => {
    const filteredTerms = this.aprFields.tierTerms.filter((t: TierTermModel) => t.term === term);
    const tierOnePlusAAF = filteredTerms.find(t => t.tier === isTierOnePlus)?.averageAmountFinanced ?? 0;

    for (const filteredTerm of filteredTerms) {
      const { tier } = filteredTerm;

      if (tier !== isTierOnePlus) {
        filteredTerm.averageAmountFinanced = tierOnePlusAAF;
      }
    }
  };

  updateRemainingRateFields = (term: number) => {
    const filteredTerms = this.aprFields.tierTerms.filter((t: TierTermModel) => t.term === term);
    const tierOnePlusRate = filteredTerms.find(t => t.tier === isTierOnePlus)?.rate ?? 0;

    for (const filteredTerm of filteredTerms) {
      const { tier } = filteredTerm;
      const exludedTiers = this.isLexus ? ['1', '2'] : ['1'];

      if (tier !== isTierOnePlus) {
        exludedTiers.includes(tier) ? (filteredTerm.rate = tierOnePlusRate) : (filteredTerm.rate = this.isLexus ? filteredTerm.standardRate : processRate(tierOnePlusRate, tier));
      }
    }
  };

  updateRemainingTfsFields = (term: number) => {
    const filteredTerms = this.aprFields.tierTerms.filter((t: TierTermModel) => t.term === term);
    const tierOnePlusTfs = filteredTerms.find(t => t.tier === isTierOnePlus)?.tfsShare ?? 0;

    for (const filteredTerm of filteredTerms) {
      const { tier } = filteredTerm;

      if (tier !== isTierOnePlus) {
        filteredTerm.tfsShare = tierOnePlusTfs;
      }
    }
  };

  // For TermRateTable and TiersList
  processRateErrors = (term: number) => {
    const filteredTerms = this.aprFields.tierTerms.filter((t: TierTermModel) => t.term === term);

    for (const filteredTierTerm of filteredTerms) {
      const { rate, standardRate, tier } = filteredTierTerm;
      const foundTierItem = this.tiersList.find(t => t.tier === tier);
      if (foundTierItem) {
        foundTierItem.hasError = Number(rate) > standardRate;
      }
    }
  };

  // For TermTable and TiersList
  processTermTableErrors = () => {
    for (const tiersListItem of this.tiersList.filter(t => t.tier !== VIEW_ALL_RATES)) {
      const filteredByTier = this.aprFields.tierTerms.filter(t => t.tier === tiersListItem.tier);
      tiersListItem.hasError = !tierTermIsComplete(filteredByTier);
      tiersListItem.isComplete = tierTermIsComplete(filteredByTier);
    }

    const allTiersComplete = isComplete(this.aprFields.tierTerms) && this.allTierTermPenRatesIsValid();
    const viewAllRatesItem = this.tiersList[this.tiersList.length - 1];
    viewAllRatesItem.hasError = !allTiersComplete;
    viewAllRatesItem.isComplete = allTiersComplete;
  };

  // used for saveApr
  hasNoErrors = () => {
    const termTablePenRatesError = this.selectedTier !== VIEW_ALL_RATES ? !this.termTablePenRatesHasError : this.allTierTermPenRatesIsValid();

    return (
      this.offerFields.name &&
      this.offerFields.penetrationRate &&
      !this.tierPenRatesHasError &&
      this.allTierTermPenRatesIsValid() &&
      termTablePenRatesError &&
      this.tiersList.every(t => t.isComplete && !t.hasError) &&
      this.aprFields.tierTerms.every(t => !t.rateError && !t.tfsError && !t.avgAmtFinancedError) &&
      !this.subCashError &&
      !this.costShareError &&
      !this.costShareCapError
    );
  };

  processTierTermRates = () => {
    this.applyStdRatesDisabled = this.aprFields.tierTerms.every(t => Number(t.rate) === t.standardRate);
  };

  updateRemainingPenRateFields = (term: number) => {
    const filteredTerms = this.aprFields.tierTerms.filter((t: TierTermModel) => t.term === term);
    const tierOnePlusPenRate = filteredTerms.find(t => t.tier === isTierOnePlus)?.penetrationRate ?? 0;

    for (const filteredTerm of filteredTerms) {
      const { tier } = filteredTerm;

      if (tier !== isTierOnePlus) {
        filteredTerm.penetrationRate = tierOnePlusPenRate;
      }
    }
  };

  updateTierPenRate = (tier: string, value: number) => {
    const foundPenRate = this.aprFields.tierPenRates.find((t: TierPenRateModel) => t.tier === tier);

    if (foundPenRate) {
      foundPenRate.penetrationRate = value;
      this.tierPenRatesHasError = this.calcTotalPenRate() !== 100;
    }
  };

  allTierTermPenRatesIsValid = () => {
    return this.aprFields.tierTerms.reduce((acc, curr) => acc + Number(curr.penetrationRate), 0) === 400;
  };

  calcByTierTerm = (tierTerm: TierTermModel, term: number, defaultTfsShares: { [term: number]: number }, ncsRate: number) => {
    calcPnvsValues(tierTerm, term, defaultTfsShares, ncsRate);
    this.calcTermWeightedCosts();
    this.calcTotalPnvs();
  };

  calcTotalPnvs = () => {
    this.aprFields.offerPnvs = this.aprFields.termWeightedCosts.reduce((acc, curr) => acc + curr.pnvs, 0) + this.aprFields.subCashPnvs;
    this.aprFields.offerTfsPnvs = this.aprFields.termWeightedCosts.reduce((acc, curr) => acc + curr.tfsPnvs + curr.ncsTfsPnvs, 0) + this.aprFields.subCashTfsPnvs;
    this.aprFields.offerEnhancedTfsPnvs =
      this.aprFields.termWeightedCosts.reduce((acc, curr) => acc + curr.enhancedTfsPnvs + curr.ncsEnhancedTfsPnvs, 0) + this.aprFields.subCashEnhancedTfsPnvs;
  };

  calcTermWeightedCosts = () => {
    for (const termWeightedCost of this.aprFields.termWeightedCosts) {
      const filteredByTerm = this.aprFields.tierTerms.filter(t => t.term === termWeightedCost.term);
      calculateWeightedTermPnvsValues(termWeightedCost, filteredByTerm, this.aprFields.tierPenRates);
    }
  };

  calcSubCash = () => {
    const { subCashCostShare, subCashCostShareCap, subCashAmount } = this.aprFields;
    const { subCashEstCost, subCashTfsEstCost, subCashEnhTfsEstCost } = SubCashCalcFns.calculateSubventionCashEstimatedCost(
      Number(subCashAmount),
      0,
      0,
      Number(subCashCostShare) / 100,
      Number(subCashCostShareCap),
      0,
      false,
    );

    this.aprFields.subCashPnvs = Math.round(subCashEstCost);
    this.aprFields.subCashTfsPnvs = Math.round(subCashTfsEstCost);
    this.aprFields.subCashEnhancedTfsPnvs = Math.round(subCashEnhTfsEstCost);

    this.calcTotalPnvs();
  };

  calcTotalPenRate = () => {
    return this.aprFields.tierPenRates.reduce((acc, curr) => acc + Number(curr.penetrationRate), 0);
  };

  calcTierTermPenRate = (tier: string) => {
    return this.aprFields.tierTerms.filter(t => t.tier === tier).reduce((acc, curr) => acc + Number(curr.penetrationRate), 0);
  };

  calcTargetPayment = (rate: TierTermModel) => {
    return APRCalcFns.calculateEstimatedPayment(Number(rate.averageAmountFinanced) || 0, Number(rate.rate) || 0, Number(rate.term) || 0);
  };

  recalculateAll = () => {
    for (const tierTerm of this.aprFields.tierTerms) {
      this.calcByTierTerm(tierTerm, tierTerm.term, this.defaultTfsShares, this.ncsRate);
    }

    this.processTermTableErrors();
    this.processTierTermRates();
  };

  isTfsCostShareEnhanced = (term: number, value: number) => {
    return this.defaultTfsShares[term] < value;
  };

  get hasEnhancedSubCashTfsCostShareTier() {
    return Number(this.aprFields.subCashCostShare) > 0;
  }

  get hasEnhancedTfsCostShareTier() {
    return this.tierTerms.some(tierTerm => this.isTfsCostShareEnhanced(tierTerm.term, Number(tierTerm.tfsShare)));
  }

  get tierTerms() {
    if (this.selectedTier === VIEW_ALL_RATES) {
      return this.aprFields.tierTerms;
    }

    return this.aprFields.tierTerms.filter((item: TierTermModel) => item.tier === this.selectedTier);
  }

  get aprRatesFor1Plus() {
    return this.aprFields?.tierTerms?.filter((item: TierTermModel) => item.tier === isTierOnePlus);
  }

  // for pen rate in CommonFields
  get penetrationRateError() {
    return !!validator(this.offerFields.penetrationRate, { required: true, min: 0, max: 100 });
  }

  get subCashError() {
    return !!validator(this.aprFields.subCashAmount, { required: true, min: 0 });
  }

  get costShareError() {
    return !!validator(this.aprFields.subCashCostShare, { required: true, min: 0, max: 100 });
  }

  get costShareCapError() {
    return !!validator(this.aprFields.subCashCostShareCap, { required: true, min: 0 });
  }
}

export default AprModel;
