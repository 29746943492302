import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { FEATURE_OR_4154 } from '../../../../../constants/global';
import {
  CostShareItem,
  SaveCostShareItemInput,
  SeriesProfile,
  useFetchSeriesProfilesByIdLazyQuery,
  useSaveCostShareItemMutation,
  useUpdateSeriesProfilesMutation,
} from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import FooterCtas from '../../../components/FooterCtas';
import { createSeriesProfilesParam } from '../../utils/createSeriesProfilesParam';
import { disableTypeDropdownOnSave } from '../../utils/disableTypeDropdownOnSave';
import { handleInvalidDatesCheck } from '../../utils/handleInvalidDatesCheck';
import { handleOverlappingDatesCheck } from '../../utils/handleOverlappingDatesCheck';
import DatesError from '../DatesError';

const Footer = () => {
  const {
    costShareTabStore: { hasProfileSeriesError, setHasUnsavedChanges, costShares, deletedCostShares, setSeriesProfiles },
    nationalCostShareStore: {
      costSharesBySeriesProfile,
      addedSeriesOptions,
      updateRev,
      id,
      rev,
      hasInvalidSeriesProfile,
      setSeries,
      hasOverlapDatesError,
      updateHasOverLapDatesError,
      updateHasInvalidDatesError,
      updateCostShares,
      hasInvalidDatesError,
    },
    nationalCostShareStore,
    ipSettingsStore: { offering },
  } = useStores();
  const { error } = useToast();
  const [updateSeriesProfiles] = useUpdateSeriesProfilesMutation();
  const [fetchSeriesProfiles] = useFetchSeriesProfilesByIdLazyQuery();
  const [saveCostShare] = useSaveCostShareItemMutation();

  const isDisabled = (FEATURE_OR_4154 && hasInvalidSeriesProfile()) || offering.isPublished;

  const handleSave = async () => {
    if (hasProfileSeriesError()) {
      return;
    }

    if (FEATURE_OR_4154) {
      const overlapError = handleOverlappingDatesCheck(addedSeriesOptions, costSharesBySeriesProfile, updateHasOverLapDatesError);
      const invalidError = handleInvalidDatesCheck(addedSeriesOptions, costSharesBySeriesProfile, updateHasInvalidDatesError);

      if (overlapError || invalidError) {
        return;
      }
    }

    try {
      if (FEATURE_OR_4154) {
        const seriesProfiles = createSeriesProfilesParam(addedSeriesOptions, costSharesBySeriesProfile);
        const input: SaveCostShareItemInput = { offeringId: offering.id, id, rev, seriesProfiles };

        const res = await trackPromise(saveCostShare({ variables: { input } }));

        if (res.data?.saveCostShareItem.success) {
          disableTypeDropdownOnSave(addedSeriesOptions, costSharesBySeriesProfile);
          updateCostShares(res.data.saveCostShareItem.updatedCostShareItem as CostShareItem);
          updateRev(res.data.saveCostShareItem.updatedCostShareItem.rev);
        }

        nationalCostShareStore.setHasUnsavedChanges(false);
      } else {
        const seriesProfilesInput = [...costShares, ...deletedCostShares].map(item => {
          return {
            ncsRate: Number(item.ncsRate),
            ncsRcf: Number(item.ncsRcf),
            rev: item.rev,
            seriesProfileId: item.seriesProfileId,
          };
        });

        const res = await trackPromise(
          updateSeriesProfiles({
            variables: { input: seriesProfilesInput },
          }),
        );

        if (res.data?.updateSeriesProfiles) {
          const response = await trackPromise(
            fetchSeriesProfiles({
              variables: {
                offeringId: offering.id,
              },
            }),
          );

          if (response.data?.fetchSeriesProfiles) {
            setSeriesProfiles(response.data.fetchSeriesProfiles as SeriesProfile[]);
            if (FEATURE_OR_4154) {
              setSeries(response.data.fetchSeriesProfiles as SeriesProfile[]);
            }
          }
        }

        setHasUnsavedChanges(false);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      {FEATURE_OR_4154 && <DatesError hasOverlapDatesError={hasOverlapDatesError} hasInvalidDatesError={hasInvalidDatesError} />}
      <FooterCtas onSave={handleSave} disabled={isDisabled} />
    </>
  );
};

export default observer(Footer);
