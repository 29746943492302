import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Button, CustomDatePicker, Dropdown, DropdownItem, NumberInput, dateFormatISOString, dateStringToDate } from 'oat-common-ui';
import useStores from '../../../../../stores/useStores';
import NationalCostShareModel, { CostShareFields } from '../../models/NationalCostShareModel';
import styles from './styles.module.scss';

interface Props {
  costShare: NationalCostShareModel;
  seriesProfileId: string;
}

const { OPTION_TYPE_NAMES } = AdminConstants;

const CostShareRow = ({ costShare, seriesProfileId }: Props) => {
  const {
    ipSettingsStore: { offering },
    nationalCostShareStore: { deleteCostShare, setHasUnsavedChanges },
  } = useStores();

  const typeOptions: DropdownItem[] = [
    { value: OPTION_TYPE_NAMES.APR, label: OPTION_TYPE_NAMES.APR },
    { value: OPTION_TYPE_NAMES.LEASE, label: OPTION_TYPE_NAMES.LEASE },
  ];

  const isDisabled = offering.isPublished;

  const handleFieldChange = <T extends keyof CostShareFields, V extends CostShareFields[T]>(field: T, value: V) => {
    costShare.updateField(field, value);

    // when user switches from APR to Lease or vice versa, reset cost share value
    if (field === 'optionTypeName') {
      costShare.updateField('value', '');
    }

    setHasUnsavedChanges(true);
  };

  const handleDeleteCostShare = () => {
    deleteCostShare(seriesProfileId, costShare.id);
    setHasUnsavedChanges(true);
  };

  return (
    <div className={styles.costShareRow}>
      <div className={styles.costShareInputs}>
        <div className={clsx(styles.dropDown, !costShare.fields.optionTypeName ? styles.defaultBtnColor : styles.selectedBtnColor)}>
          <Dropdown
            id={`${costShare.id}-type`}
            placeholder="Type"
            options={typeOptions}
            value={costShare.fields.optionTypeName}
            onChange={val => handleFieldChange('optionTypeName', val.value)}
            darkTheme
            disabled={isDisabled || costShare.optionTypeNameIsDisabled}
          />
        </div>
        <NumberInput
          dark
          className={styles.costShareInput}
          id={`${costShare.id}-cost-share`}
          onChange={e => handleFieldChange('value', Number(e.target.value))}
          value={costShare.fields.value}
          dollar={costShare.isApr}
          rcf={costShare.isLease}
          allowNegative={false}
          disabled={!costShare.fields.optionTypeName || isDisabled}
        />
        <div className={clsx(styles.datesParent)}>
          <CustomDatePicker
            darkTheme
            id={`${costShare.id}-start-date`}
            selected={costShare.fields.startDate ? dateStringToDate(costShare.fields.startDate) : undefined}
            onChange={val => handleFieldChange('startDate', dateFormatISOString(val))}
            minDate={dateStringToDate(offering.startDate)}
            maxDate={dateStringToDate(offering.endDate)}
            placeholderText="Start date"
            error={costShare.hasOverlappingDates || costShare.hasDatesError}
            disabled={isDisabled}
          />
          <div className={styles.datesDivider}>
            <span className={styles.dash} />
          </div>
          <CustomDatePicker
            darkTheme
            id={`${costShare.id}-end-date`}
            selected={costShare.fields.endDate ? dateStringToDate(costShare.fields.endDate) : undefined}
            onChange={val => handleFieldChange('endDate', dateFormatISOString(val))}
            minDate={dateStringToDate(offering.startDate)}
            maxDate={dateStringToDate(offering.endDate)}
            placeholderText="End date"
            error={costShare.hasOverlappingDates || costShare.hasDatesError}
            disabled={isDisabled}
          />
        </div>
      </div>
      <Button variant="text" id={`${costShare.id}-delete-btn`} className={styles.deleteBtn} onClick={() => handleDeleteCostShare()} disabled={isDisabled}>
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </Button>
    </div>
  );
};

export default observer(CostShareRow);
