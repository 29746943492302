import CostShareDatesWrapper from '../CostShareDatesWrapper';
import LeftSide from '../LeftSide';
import RightSide from '../RightSide';

const EnhancedCostShareDates = () => {
  return (
    <CostShareDatesWrapper>
      <LeftSide />
      <RightSide />
    </CostShareDatesWrapper>
  );
};

export default EnhancedCostShareDates;
