import { invalidDatesText, overlapAndInvalidDatesText, overlapDatesText } from '../../../../../constants/global';
import styles from './styles.module.scss';

interface Props {
  hasOverlapDatesError: boolean;
  hasInvalidDatesError: boolean;
}

const DatesError = ({ hasOverlapDatesError, hasInvalidDatesError }: Props) => {
  let errorMessage = '';

  if (hasOverlapDatesError && !hasInvalidDatesError) {
    errorMessage = overlapDatesText;
  }

  if (hasInvalidDatesError && !hasOverlapDatesError) {
    errorMessage = invalidDatesText;
  }

  if (hasOverlapDatesError && hasInvalidDatesError) {
    errorMessage = overlapAndInvalidDatesText;
  }

  return <span className={styles.errorMsg}>{errorMessage}</span>;
};

export default DatesError;
