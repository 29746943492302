import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { dateStringToDate } from 'oat-common-ui';
import { ReactNode, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import WarningModal from '../../../pages/FYRollup/components/header/Navbar/components/WarningModal';
import useStores from '../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  customTheme?: string;
  lessPadding?: boolean;
  paths?: string[];
  to: string;
  toLegacy?: boolean;
}

const AnchorNav = ({ children, to, customTheme, toLegacy = false, lessPadding = false, className, paths }: Props) => {
  const {
    nationalCostShareStore,
    costShareTabStore,
    fyRollupStore: { setNewRoute, isEdit, hasUnsavedChanges, initEnterFYSection, fySection, newRoute },
    fyRollupStore,
    incentiveCalendarStore,
    ipSettingsStore,
    ratesTabAPRRatesStore: { aprHasUnsavedChanges, setAprHasUnsavedChanges },
    ratesTabLeaseRCFStore: { leaseHasUnsavedChanges, setLeaseHasUnsavedChanges },
    ratesTabRVRateStore: { rvHasUnsavedChanges, setRvHasUnsavedChanges },
    ratesTabTFSLeaseRCFStore: { tfsHasUnsavedChanges, setTfsHasUnsavedChanges },
    uploadsTabStore: { uploadsHasUnsavedChanges, setUploadsHasUnsavedChanges },
    seriesAndModelsStore,
    dashboardStore,
    multiSeriesOffersBudgetStore,
    assumptionsStore,
  } = useStores();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const history = useHistory();

  const isActive = useMemo(() => {
    const pathname = history.location.pathname === '/' ? '/dashboard' : history.location.pathname;
    return pathname.includes(to) || paths?.some(path => pathname.includes(path));
  }, [history.location.pathname, paths, to]);

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, route: string, toLegacy: boolean) => {
    setNewRoute(route);

    if (
      (isEdit && hasUnsavedChanges) ||
      aprHasUnsavedChanges ||
      nationalCostShareStore.hasUnsavedChanges ||
      costShareTabStore.hasUnsavedChanges ||
      dashboardStore.hasUnsavedChanges ||
      incentiveCalendarStore.hasUnsavedChanges ||
      ipSettingsStore.hasUnsavedChanges ||
      leaseHasUnsavedChanges ||
      rvHasUnsavedChanges ||
      seriesAndModelsStore.hasUnsavedChanges ||
      tfsHasUnsavedChanges ||
      uploadsHasUnsavedChanges
    ) {
      e.preventDefault();
      setShowWarningModal(true);
      return;
    }

    if (!toLegacy) {
      e.preventDefault();
      resetToFiscalYear();
      history.push(route);
      return;
    }

    return true;
  };

  const resetToFiscalYear = () => {
    const fiscalYear = dateStringToDate(new Date()).getFullYear() + 1;

    multiSeriesOffersBudgetStore.setIsFetched(false);
    multiSeriesOffersBudgetStore.setFiscalYear(fiscalYear);
    assumptionsStore.setIsFetched(false);
    assumptionsStore.setFiscalYear(fiscalYear);
  };

  const handleUnsavedChangesReset = () => {
    nationalCostShareStore.setHasUnsavedChanges(false);
    costShareTabStore.setHasUnsavedChanges(false);
    seriesAndModelsStore.setHasUnsavedChanges(false);
    setRvHasUnsavedChanges(false);
    setAprHasUnsavedChanges(false);
    setLeaseHasUnsavedChanges(false);
    setTfsHasUnsavedChanges(false);
    setUploadsHasUnsavedChanges(false);
    fyRollupStore.setHasUnsavedChanges(false);
    seriesAndModelsStore.setHasUnsavedChanges(false);
    dashboardStore.setUnsavedChanges(false);
    incentiveCalendarStore.setHasUnsavedChanges(false);
    ipSettingsStore.setHasUnsavedChanges(false);
  };

  const handleOnSubmit = () => {
    toLegacy ? window.location.assign(newRoute) : history.push(newRoute);
    setShowWarningModal(false);
    initEnterFYSection();
    handleUnsavedChangesReset();
    nationalCostShareStore.resetCostShareDatesErrors();
  };

  return (
    <>
      {showWarningModal && <WarningModal id={fySection} onClose={() => setShowWarningModal(false)} onSubmit={() => handleOnSubmit()} />}
      <a href={to} onClick={e => handleOnClick(e, to, toLegacy)} className={clsx(!!customTheme && customTheme, isActive && styles.activeNav, className)}>
        {children}
      </a>
    </>
  );
};

export default observer(AnchorNav);
