import { DropdownItem, dateStringToDate } from 'oat-common-ui';
import { ICostShareSeriesProfile } from '../../../../stores/NationalCostShareStore';

export const handleOverlappingDatesCheck = (
  addedSeriesOptions: DropdownItem[],
  costSharesBySeriesProfile: Map<string, ICostShareSeriesProfile>,
  updateHasOverLapDatesError: (val: boolean) => void,
) => {
  let overlapCount = 0;
  updateHasOverLapDatesError(false);

  for (const addedSeriesOption of addedSeriesOptions) {
    const costSharesBySp = costSharesBySeriesProfile.get(addedSeriesOption.value);

    if (costSharesBySp?.costShares.length) {
      const costShares = costSharesBySp?.costShares;
      costShares.forEach(cs => cs.hasOverlappingDates && cs.updateHasOverlappingDates(false));

      for (let i = 0; i < costShares.length; i++) {
        const costShareToTest = costShares[i];
        const costSharesToTestAgainst = costShares.filter(cs => costShareToTest.id !== cs.id && costShareToTest.fields.optionTypeName === cs.fields.optionTypeName);

        for (let j = 0; j < costSharesToTestAgainst.length; j++) {
          const startDateA = dateStringToDate(costShareToTest.fields.startDate).getTime();
          const startDateB = dateStringToDate(costSharesToTestAgainst[j].fields.startDate).getTime();
          const endDateA = dateStringToDate(costShareToTest.fields.endDate).getTime();
          const endDateB = dateStringToDate(costSharesToTestAgainst[j].fields.endDate).getTime();

          const datesOverlap = startDateA <= endDateB && endDateA >= startDateB;

          if (datesOverlap) {
            costShareToTest.updateHasOverlappingDates(true);
            costSharesToTestAgainst[j].updateHasOverlappingDates(true);
            overlapCount += 1;
          }
        }
      }
    }
  }

  if (overlapCount > 0) {
    updateHasOverLapDatesError(true);
    return true;
  }
};
