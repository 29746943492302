import { observer } from 'mobx-react-lite';
import { AdminConstants, LeaseLocks } from 'oat-admin-common';
import { Dropdown, OATIcon, formatDollars, formatRcf } from 'oat-common-ui';
import BlockInputLabel from '../../../../../components/BlockInputLabel';
import { FEATURE_OR_4154 } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import { appendTfsLabel } from '../../../../../utils/appendTfsLabel';
import TfsCostSharesSelector from '../../../components/TfsCostSharesSelector';
import SeriesProfileModel from '../../../models/SeriesProfileModel';
import LeaseFormModel from '../../../models/offers/lease/LeaseFormModel';
import LeaseModel from '../../../models/offers/lease/LeaseModel';
import LeaseInput from '../components/LeaseInput';
import { termDDItems } from '../constants';
import styles from './styles.module.scss';

interface Props {
  lease: LeaseModel;
  leaseForm: LeaseFormModel;
  seriesProfile: SeriesProfileModel;
  onSave: () => void;
}

const MiddleColumn = ({ lease, leaseForm, seriesProfile, onSave }: Props) => {
  const {
    nationalProgramsStore: {
      residualValues,
      standardRates: { rvDifferential, leaseRates },
    },
  } = useStores();
  const { uid, leaseFields, applyStandardRcf, updateLeaseFields, updateCostShareId, updateMileage, updateTerm, selectedCostShare } = leaseForm;

  const handleSelect = (id: string | null, value: number) => {
    const leaseExamples = lease.getExamples();

    if (leaseExamples?.length) {
      leaseExamples.forEach(ex => ex.updateCostShareId(id, value, leaseRates));
    }

    updateCostShareId(id, value, leaseRates);
    onSave();
  };

  return (
    <div className={styles.divider}>
      {FEATURE_OR_4154 && (
        <TfsCostSharesSelector
          isUpdated={Boolean(leaseFields.isCostShareUpdated && seriesProfile.data.isCostShareUpdated)}
          id={uid}
          offerType="Lease"
          onSelect={(id, value) => handleSelect(id, value)}
          selectedTfsCost={leaseFields.costShareId}
          seriesProfileId={seriesProfile.id}
          leaseForm={leaseForm}
        />
      )}
      <div className={styles.row}>
        {/* RCF */}
        <LeaseInput
          id={`l-rcf-${uid}`}
          label="RCF"
          value={leaseFields.rcf}
          lock={LeaseLocks.RCF}
          error={leaseForm.rcfError}
          lease={leaseForm}
          disabled={leaseForm.isExample}
          onValueChange={val => {
            updateLeaseFields('rcf', val.value);
            onSave();
          }}
          rcf
          subLabel={
            <button
              id={`apply-std-rcf-${uid}`}
              className={styles.applyStdRcfBtn}
              disabled={leaseForm.lock === LeaseLocks.RCF || leaseForm.isExample}
              onClick={() => {
                applyStandardRcf();
                onSave();
              }}
            >
              <OATIcon icon="reset" />
              Apply Standard RCF
            </button>
          }
        />

        {/* Standard/Enhanced RCF */}
        <BlockInputLabel label={`${seriesProfile.data.ncsRcf || selectedCostShare ? 'Effective' : 'Standard'} RCF`} labelClass={styles.costLabel} smallLabel>
          <span className={styles.costText}>{formatRcf(leaseFields.standardRcf ?? 0)}</span>
        </BlockInputLabel>

        {/* Est. Buydown Cost */}
        <BlockInputLabel label="Est. Buydown Cost" labelClass={styles.costLabel} smallLabel>
          <span className={styles.costText}>${formatDollars(leaseFields.rcfPnvs ?? 0)}</span>
        </BlockInputLabel>
      </div>

      <div className={styles.row}>
        {/* Target Payment */}
        <LeaseInput
          id={`l-tp-${uid}`}
          label="Target Payment"
          value={leaseFields.targetPayment}
          lock={LeaseLocks.TARGET_PAYMENT}
          error={leaseForm.tpError}
          lease={leaseForm}
          onValueChange={val => {
            updateLeaseFields('targetPayment', val.value);
            onSave();
          }}
          dollarSign
          wholeNumber
          allowNegative
        />

        {/* Due At Signing */}
        <LeaseInput
          id={`l-das-${uid}`}
          label="Due At Signing"
          value={leaseFields.dueAtSigning}
          error={leaseForm.dasError}
          lock={LeaseLocks.DUE_AT_SIGNING}
          lease={leaseForm}
          onValueChange={val => {
            updateLeaseFields('dueAtSigning', val.value);
            onSave();
          }}
          dollarSign
          wholeNumber
        />

        {/* Dealer Gross */}
        <LeaseInput
          id={`l-dg-${uid}`}
          label="Dealer Gross"
          value={leaseFields.dealerGross}
          error={leaseForm.dgError}
          lock={LeaseLocks.DEALER_GROSS}
          lease={leaseForm}
          onValueChange={val => {
            updateLeaseFields('dealerGross', val.value);
            onSave();
          }}
          dollarSign
          wholeNumber
          allowNegative
        />
      </div>

      <div className={styles.row}>
        {/* Subvention Cash */}
        <LeaseInput
          id={`l-subcaash-${uid}`}
          label={appendTfsLabel(leaseForm.brand === AdminConstants.BRAND_LEXUS, 'Subvention Cash')}
          value={leaseFields.subCashAmount}
          lease={leaseForm}
          error={leaseForm.subCashError}
          lock={LeaseLocks.SUBVENTION_CASH}
          disabled={leaseForm.isExample}
          onValueChange={val => {
            updateLeaseFields('subCashAmount', val.value);
            onSave();
          }}
          dollarSign
          wholeNumber
        />

        {/* Mileage */}
        <BlockInputLabel className={styles.mileageInput} label="Annual Mileage" smallLabel>
          <Dropdown
            disabled={seriesProfile.data.isBudgetConfirmed}
            id={`l-mileage-${uid}`}
            isNational
            value={leaseForm.mileages.find(item => item.value === leaseFields.mileage.toString())}
            onChange={item => {
              updateMileage(Number(item.value), residualValues, rvDifferential);
              onSave();
            }}
            options={leaseForm.mileages}
          />
        </BlockInputLabel>

        {/* Term */}
        <BlockInputLabel label="Term" smallLabel>
          <Dropdown
            disabled={seriesProfile.data.isBudgetConfirmed}
            id={`l-term-${leaseForm.uid}`}
            isNational
            value={termDDItems.find(item => item.value === leaseFields.term.toString())}
            onChange={item => {
              updateTerm(lease, Number(item.value), residualValues, rvDifferential);
              onSave();
            }}
            options={termDDItems}
          />
        </BlockInputLabel>
      </div>
    </div>
  );
};

export default observer(MiddleColumn);
