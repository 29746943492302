import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { DefaultModal, DropResult, OATIcon, sortFieldsHelper, useToast } from 'oat-common-ui';
import { useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import Button from '../../../../components/Button';
import { sortDragItemsList } from '../../../../components/DragSortingItem/utils';
import {
  Offer,
  OfferingCosts,
  Scenario,
  SeriesProfile,
  useConfirmBudgetViewMutation,
  useConfirmSetupViewMutation,
  useCopyScenarioMutation,
  useCreateScenarioForSpMutation,
  useDeleteScenarioMutation,
  useExportScenariosMutation,
  useGetAtcOfferingChangesLazyQuery,
  useRenameScenarioMutation,
  useSaveSeriesProfileScenarioSortOrderMutation,
} from '../../../../gql/generated';
import { appendTfsLabel } from '../../../../utils/appendTfsLabel';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import ScenarioActionModal, { ScenarioModalLayout } from '../../components/SeriesProfileSection/ScenarioActionModal';
import { createExportScenarioInput } from '../../components/SeriesProfileSection/ScenarioActionModal/ExportScenarioLayout/utils/createExportScenarioInput';
import { processSeriesProfiles } from '../../components/SeriesProfileSection/ScenarioActionModal/ExportScenarioLayout/utils/processSeriesProfiles';
import ScenarioModel from '../../models/ScenarioModel';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import ScenariosSection from './components/ScenariosSection';
import SeriesHeaderButtons from './components/SeriesHeaderButtons';
import SeriesTitle from './components/SeriesTitle';
import styles from './styles.module.scss';

interface Props {
  seriesProfile: SeriesProfileModel;
}

const SeriesProfileHeader = ({ seriesProfile }: Props) => {
  const {
    globalStore: { setShowProgressBar },
    nationalProgramsStore: { seriesProfiles, setChanges, isBudgetAndSetupConfirmed },
    offeringCostsStore: { updateOfferingCostsFromResponse },
    userInfoStore: { isLexus },
  } = useStores();
  const { error } = useToast();
  const { offeringId } = useUrlParams();

  const {
    clearErrors,
    data,
    id,
    isOverridableBudgetError,
    scenarios,
    tempScenarios,
    selectedScenario,
    setCofirmSetupErrors,
    setErrors,
    setIsOverridableBudgetError,
    setIsSaving,
    toggleIsSetupConfirmed,
    updateDataField,
    sortScenariosList,
    setTempScenariosList,
  } = seriesProfile;

  const [createScenario] = useCreateScenarioForSpMutation();
  const [renameScenario] = useRenameScenarioMutation();
  const [removeScenario] = useDeleteScenarioMutation();
  const [copyScenario] = useCopyScenarioMutation();
  const [reorderScenario] = useSaveSeriesProfileScenarioSortOrderMutation();
  const [exportScenarios] = useExportScenariosMutation();
  const [confirmBudgetView] = useConfirmBudgetViewMutation();
  const [confirmSetupView] = useConfirmSetupViewMutation();
  const [getAtcOfferingChanges] = useGetAtcOfferingChangesLazyQuery();

  const [modalLayout, setModalLayout] = useState<ScenarioModalLayout | undefined>();
  const [addScenarioInProgress, setAddScenarioInProgress] = useState(false);

  // hasOfferWithUpdatedCostShare === null means that we didn't fetched rgnlAltSummary
  // after rgnlAltSummary is fetched we can use hasOfferWithUpdatedCostShare to hide message on TfsCostSharesSelector change
  const isSPCostShareUpdate = useMemo(() => {
    if (seriesProfile.hasOfferWithUpdatedCostShare === null) {
      if (seriesProfile.data.isCostShareUpdated) {
        return true;
      }
    } else if (seriesProfile.hasOfferWithUpdatedCostShare === false) {
      return false;
    } else {
      return seriesProfile.data.isCostShareUpdated;
    }
  }, [seriesProfile.data.isCostShareUpdated, seriesProfile.hasOfferWithUpdatedCostShare]);

  const handleOpenModal = (type: ScenarioModalLayout) => {
    setModalLayout(type);
    seriesProfiles.forEach(sp => sp.resetExportScenarios());
  };

  const handleCloseModal = () => {
    setModalLayout(undefined);
    seriesProfiles.forEach(sp => {
      sp.setExportTo(false);
      sp.resetExportScenarios();
    });

    if (modalLayout === 'Reorder') {
      setTempScenariosList(scenarios);
    }
  };

  const handleRenameScenario = async (scenarioId: string, name: string) => {
    try {
      if (selectedScenario) {
        const res = await trackPromise(renameScenario({ variables: { input: { scenarioId, name, rev: selectedScenario.rev } } }));
        if (res.data?.renameScenario) {
          seriesProfile.renameScenario(res.data.renameScenario.data as Scenario);
        }
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      handleCloseModal();
    }
  };

  const handleAddScenario = async (scenario: string) => {
    try {
      setAddScenarioInProgress(true);
      const res = await trackPromise(createScenario({ variables: { input: { name: scenario, seriesProfileId: id, rev: data.rev } } }));
      if (res.data?.createScenarioForSP.success) {
        seriesProfile.addScenario(res.data.createScenarioForSP.data as Scenario);
        updateOfferingCostsFromResponse(res.data.createScenarioForSP.offeringCosts as OfferingCosts);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setAddScenarioInProgress(false);
      handleCloseModal();
    }
  };

  const handleDeleteScenario = async () => {
    try {
      if (selectedScenario) {
        const res = await trackPromise(removeScenario({ variables: { input: { scenarioId: selectedScenario.id, scenarioRev: selectedScenario.rev } } }));
        if (res.data?.deleteScenario.success) {
          seriesProfile.removeScenario(selectedScenario.id);
          seriesProfile.selectScenario(res.data.deleteScenario.selectedScenario.id, res.data.deleteScenario.selectedScenario.rev);
          updateOfferingCostsFromResponse(res.data.deleteScenario.offeringCosts as OfferingCosts);
          setTempScenariosList(tempScenarios.filter(sceanrio => sceanrio.id !== selectedScenario.id));
        }
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      handleCloseModal();
    }
  };

  const handleCopyScenario = async (scenarioName: string) => {
    try {
      if (selectedScenario) {
        const res = await trackPromise(copyScenario({ variables: { input: { scenarioId: selectedScenario.id, scenarioRev: selectedScenario.rev, name: scenarioName } } }));
        if (res.data?.copyScenario.success) {
          // set rev for source scenario
          selectedScenario.updateRev(res.data.copyScenario.sourceScenarioRev);
          // add and select new scenario
          seriesProfile.addScenario(res.data.copyScenario.scenario as Scenario);
          seriesProfile.selectScenario(res.data.copyScenario.scenario.id, res.data.copyScenario.scenario.rev);
          updateOfferingCostsFromResponse(res.data.copyScenario.offeringCosts as OfferingCosts);
        }
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      handleCloseModal();
    }
  };

  const handleExportScenario = async () => {
    const input = createExportScenarioInput(offeringId, scenarios, seriesProfiles);

    try {
      const res = await trackPromise(exportScenarios({ variables: { input } }));

      if (res.data?.exportScenarios.success) {
        const affectedSPs = res.data?.exportScenarios.seriesProfiles;
        const offeringCosts = res.data?.exportScenarios.offeringCosts;

        processSeriesProfiles(affectedSPs as SeriesProfile[], seriesProfiles);
        updateOfferingCostsFromResponse(offeringCosts);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      handleCloseModal();
    }
  };

  const updateOfferRevs = (sp: SeriesProfile) => {
    if (selectedScenario?.rgnlAlts) {
      const updatedScenario = sp!.scenarios!.find(item => item?.id === selectedScenario.id);
      const updatedOffers = updatedScenario?.rgnlAlts?.flatMap(rgnlAlt => rgnlAlt?.offers) ?? [];
      selectedScenario.rgnlAlts.forEach(ra => {
        for (const offer of ra.offers) {
          const updatedOffer = updatedOffers.find(item => item?.id === offer.getOfferId());
          if (!updatedOffer) {
            continue;
          }
          if (updatedOffer.optionType === AdminConstants.OPTION_TYPE_NAMES.LEASE) {
            const examples = updatedOffers.filter(item => item?.masterOfferId === updatedOffer.id) as Offer[];
            offer.updateRev([updatedOffer, ...examples]);
          } else {
            offer.updateRev([updatedOffer]);
          }
        }
      });
    }
  };

  const handleConfirmBudget = async (overridePNVSThreshold = false, overridePenRateErrors = false) => {
    try {
      setIsSaving(true);
      if (data.isBudgetConfirmed || overridePenRateErrors) {
        overridePNVSThreshold = overridePenRateErrors = true;
      }
      const payload = {
        seriesProfileId: id,
        isConfirmed: !data.isBudgetConfirmed,
        rev: data.rev,
        overridePNVSThreshold: overridePNVSThreshold,
        overridePenRateErrors: overridePenRateErrors,
      };
      const resp = await trackPromise(confirmBudgetView({ variables: { input: payload } }));
      if (resp.data?.confirmBudgetView.success) {
        const sp = resp.data.confirmBudgetView.seriesProfile as SeriesProfile;
        updateDataField('rev', sp?.rev ?? '');
        updateOfferRevs(sp);
        updateDataField('hasOffersForRegions', sp.hasOffersForRegions ?? false);
        updateDataField('isBudgetConfirmed', sp.isBudgetConfirmed);
        updateDataField('isSetupConfirmed', sp.isSetupConfirmed);
        updateDataField('isCostShareUpdated', sp.isCostShareUpdated);
      }
      setErrors(resp.data?.confirmBudgetView.errors || []);
      setIsOverridableBudgetError(Boolean(resp.data?.confirmBudgetView.isOverridable));
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleGetAtcOfferingChanges = async () => {
    try {
      const res = await trackPromise(getAtcOfferingChanges({ variables: { offeringId } }));

      if (res.data?.getAtcOfferingChanges) {
        const { hasBudgetChanges, hasSetupChanges, hasEnhancedRcfChanges } = res.data.getAtcOfferingChanges;
        setChanges(hasBudgetChanges, hasSetupChanges, hasEnhancedRcfChanges);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleConfirmSetup = async () => {
    try {
      setIsSaving(true);
      setShowProgressBar(true);

      const resp = await trackPromise(confirmSetupView({ variables: { input: { rev: data.rev, seriesProfileId: data.id, isConfirmed: !data.isSetupConfirmed } } }));
      if (resp.data?.confirmSetupView.success) {
        updateDataField('rev', resp.data.confirmSetupView.rev ?? '');
        toggleIsSetupConfirmed();
      }

      const offerErrors = resp.data?.confirmSetupView.offerErrors;
      const seriesProfileErrors = resp.data?.confirmSetupView.seriesProfileErrors;
      const rgnlAltErrors = resp.data?.confirmSetupView.rgnlAltErrors;

      const spError = seriesProfileErrors?.find(sp => sp.entityId === id);
      setCofirmSetupErrors(spError?.errors || []);

      selectedScenario?.rgnlAlts.forEach(rgnlAlt => {
        const rgnlAltError = rgnlAltErrors?.find(rgAl => rgAl.entityId === rgnlAlt.data.id);

        rgnlAlt.setSetupConfirmErrors(rgnlAltError?.errors || []);

        rgnlAlt.setupOffers.forEach(setupOffer => {
          const offerError = offerErrors?.find(offer => offer.entityId === setupOffer.id);

          setupOffer.setSetupConfirmErrors(offerError?.errors || []);
        });
      });

      if (isBudgetAndSetupConfirmed()) {
        await handleGetAtcOfferingChanges();
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsSaving(false);
      setShowProgressBar(false);
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    setTempScenariosList(sortDragItemsList(tempScenarios, result.source.index, result.destination.index).map((item, i) => ({ ...item, sortOrder: i })) as ScenarioModel[]);
  };

  const handleOnReorderScenarios = async () => {
    try {
      setIsSaving(true);
      setShowProgressBar(true);

      sortScenariosList(tempScenarios.map((item, i) => ({ ...item, sortOrder: i })));
      await trackPromise(reorderScenario({ variables: { input: { seriesProfileId: id, rev: data.rev, scenarioSortOrder: tempScenarios.map(item => item.id) } } }));
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsSaving(false);
      setShowProgressBar(false);
      setModalLayout(undefined);
      clearErrors();
    }
  };

  const handleSingleReorderScenario = (item: ScenarioModel, location: 'up' | 'down') => {
    const scenarios = [...tempScenarios];
    const selectedScenarioIndex = scenarios.findIndex(scenario => scenario.id === item.id);

    if ((item.sortOrder === 0 && location === 'up') || (item.sortOrder === scenarios.length - 1 && location === 'down')) {
      return;
    }

    switch (location) {
      case 'up':
        scenarios[selectedScenarioIndex].sortOrder = scenarios[selectedScenarioIndex].sortOrder - 1;
        scenarios[selectedScenarioIndex - 1].sortOrder = scenarios[selectedScenarioIndex].sortOrder + 1;
        break;
      default:
        if (selectedScenarioIndex < scenarios.length) scenarios[selectedScenarioIndex].sortOrder = scenarios[selectedScenarioIndex].sortOrder + 1;
        scenarios[selectedScenarioIndex + 1].sortOrder = scenarios[selectedScenarioIndex].sortOrder - 1;
        break;
    }

    setTempScenariosList(scenarios.sort(sortFieldsHelper('sortOrder', true)));
  };

  return (
    <>
      <div className={clsx(styles.content, styles.sticky)}>
        <div className={styles.mainline}>
          <div className={styles.title}>
            <SeriesTitle seriesProfileModel={seriesProfile} />
            {seriesProfile.budgetConfirmErrors && isOverridableBudgetError && (
              <Button className={styles.override} variant="override" onClick={() => handleConfirmBudget(true, true)} id="override-all-btn">
                Override All
              </Button>
            )}
          </div>
          <div className={styles.ctas}>
            {isSPCostShareUpdate && (
              <p className={styles.enhCostShareWarning}>
                <OATIcon icon="warning" colorTheme="orange" />
                {`Enhanced ${appendTfsLabel(isLexus(), ' Cost Share')} has been updated. Please verify offers.`}
              </p>
            )}
            <SeriesHeaderButtons seriesProfileModel={seriesProfile} onConfirmBudget={handleConfirmBudget} onConfirmSetup={handleConfirmSetup} />
            <ScenariosSection seriesProfileModel={seriesProfile} handleOpenModal={handleOpenModal} />
          </div>
        </div>
        {seriesProfile.budgetConfirmErrors && (
          <>
            {seriesProfile.budgetConfirmErrors.includes('exceeds the threshold') ? (
              <DefaultModal
                open
                title="The proposed PNVS exceeds the threshold"
                message="Are you sure you want to confirm?"
                onClose={() => clearErrors()}
                onSubmit={() => {
                  handleConfirmBudget(true);
                  clearErrors();
                }}
                submitText="Confirm"
              />
            ) : (
              <>
                <OATIcon className={styles.warning} icon="warning" colorTheme="red" />
                <div className={styles.error}>{seriesProfile.budgetConfirmErrors}</div>
              </>
            )}
          </>
        )}
        {seriesProfile.setupConfirmErrors && (
          <>
            <OATIcon className={styles.warning} icon="warning" colorTheme="red" />
            <div className={styles.error}>{seriesProfile.setupConfirmErrors}</div>
          </>
        )}
      </div>
      {modalLayout && (
        <ScenarioActionModal
          addScenarioInProgress={addScenarioInProgress}
          handleOnDragEnd={handleOnDragEnd}
          id={selectedScenario?.id}
          layout={modalLayout}
          onAdd={handleAddScenario}
          onClose={handleCloseModal}
          onCopy={handleCopyScenario}
          onDelete={handleDeleteScenario}
          onExport={handleExportScenario}
          onRename={handleRenameScenario}
          onReorder={handleOnReorderScenarios}
          scenarioName={modalLayout !== 'Add' ? selectedScenario?.name : ''}
          tempScenarios={tempScenarios}
          scenarios={scenarios}
          seriesProfile={seriesProfile}
          moveScenario={handleSingleReorderScenario}
        />
      )}
    </>
  );
};

export default observer(SeriesProfileHeader);
