import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const CostShareDatesWrapper = ({ children }: Props) => {
  return <div className={styles.costShareWrapper}>{children}</div>;
};

export default CostShareDatesWrapper;
