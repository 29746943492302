import { AdminConstants } from 'oat-admin-common';
import { FEATURE_OR_5454_PHASE_2 } from '../../../../constants/global';

const { OPTION_TYPE_NAMES } = AdminConstants;

const buildOfferTypes = () => {
  const frequent: string[] = [
    OPTION_TYPE_NAMES.NATIONAL_RYO,
    OPTION_TYPE_NAMES.ADDITIONAL_RYO,
    OPTION_TYPE_NAMES.CUSTOMER_CASH,
    OPTION_TYPE_NAMES.APR,
    OPTION_TYPE_NAMES.LEASE,
    OPTION_TYPE_NAMES.BONUS,
  ];

  const other: string[] = [
    OPTION_TYPE_NAMES.RYO_EARNINGS,
    OPTION_TYPE_NAMES.LUMPSUM_RYO,
    FEATURE_OR_5454_PHASE_2 ? OPTION_TYPE_NAMES.COLLEGE : OPTION_TYPE_NAMES.COLLEGE_GRADUATE,
    OPTION_TYPE_NAMES.MILITARY,
    OPTION_TYPE_NAMES.DOWN_PAYMENT_ASSISTANCE,
    OPTION_TYPE_NAMES.TRADE_IN_ASSISTANCE,
    OPTION_TYPE_NAMES.COMPLIMENTARY_FIRST_PAYMENT,
    OPTION_TYPE_NAMES.PAYMENT_WAIVER,
    OPTION_TYPE_NAMES.LOYALTY,
    OPTION_TYPE_NAMES.LEASE_LOYALTY,
    OPTION_TYPE_NAMES.CONQUEST,
    OPTION_TYPE_NAMES.CAMPAIGN,
    OPTION_TYPE_NAMES.EVENT,
    OPTION_TYPE_NAMES.TARGETED_CERTIFICATE,
    OPTION_TYPE_NAMES.NON_CASH_CERTIFICATE,
    OPTION_TYPE_NAMES.VENDOR_PURCHASE_PROGRAM,
    OPTION_TYPE_NAMES.VSPP,
    OPTION_TYPE_NAMES.DEALER_CASH,
    OPTION_TYPE_NAMES.DEALER_TFS_SUBVENTION_CASH,
    OPTION_TYPE_NAMES.DEALER_TFS_CASH,
    OPTION_TYPE_NAMES.DEALER_WHOLESALE_PROGRAM,
    OPTION_TYPE_NAMES.OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH,
    OPTION_TYPE_NAMES.DEALER_TFS_APR_SUBVENTION_CASH,
    OPTION_TYPE_NAMES.DEALER_TFS_LEASE_SUBVENTION_CASH,
    OPTION_TYPE_NAMES.FINAL_PAY,
    OPTION_TYPE_NAMES.SALES_PERSON_FLAT_CASH,
    OPTION_TYPE_NAMES.SALES_MANAGER_FLAT_CASH,
    OPTION_TYPE_NAMES.TFS_APR_CASH,
    OPTION_TYPE_NAMES.TFS_LEASE_CASH,
    OPTION_TYPE_NAMES.DEFERRED_PAYMENT,
  ];

  return {
    frequent,
    other,
  };
};

export default buildOfferTypes;
