import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { assignBooleanValue, assignNumberValue, assignStringValue, uuidv4, validator } from 'oat-common-ui';
import { Offer, OfferSelection } from '../../../../gql/generated';
import RgnlAltModel from '../RgnlAltModel';
import { OfferCostFields, OfferFields, OfferFieldType } from './OfferFields';
import OfferModel from './OfferModel';

const { OPTION_TYPE_NAMES } = AdminConstants;

export interface OfferUidSelection extends OfferSelection {
  uid: string;
}

export interface NationalRYOCostFields extends OfferCostFields {
  ryoAmountCash: number;
  ryoAmountPercent: number;
  offerSelections: OfferUidSelection[];
  isPercentSelected: boolean;
}

class NationalRyoModel {
  uid = uuidv4();
  offerFields: OfferFields = {
    id: '',
    isForRegions: true,
    rev: '',
    rgnlAltId: '',
    optionType: OPTION_TYPE_NAMES.NATIONAL_RYO as string,
    name: OPTION_TYPE_NAMES.NATIONAL_RYO as string,
    penetrationRate: '',
  };
  offerCostFields: NationalRYOCostFields = {
    amount: '',
    offerEnhancedTfsPnvs: 0,
    offerPnvs: '',
    offerTfsPnvs: 0,
    ryoAmountCash: 0,
    ryoAmountPercent: 100,
    offerSelections: [],
    isPercentSelected: true,
  };

  constructor(data: { offer?: Offer; optionType?: string; rgnlAltId: string; rgnlAlts?: RgnlAltModel[] }) {
    makeAutoObservable(this);

    this.offerFields.rgnlAltId = data.rgnlAltId;
    this.offerFields.optionType = assignStringValue(data.optionType);
    this.offerFields.name = assignStringValue(data.optionType);

    // offer coming from service on load
    // note: rgnlAlts will come from offer updates
    if (data.offer) {
      this.offerFields.id = data.offer.id;
      this.offerFields.rev = data.offer.rev;
      this.offerFields.penetrationRate = data.offer.penetrationRate;
      this.setData(data.offer, data.rgnlAlts);
    }

    // when national ryo gets added (not persisted in db)
    if (!data.offer && data.rgnlAlts) {
      const filteredRas = data.rgnlAlts.filter(ra => +ra.data.number !== 1);
      this.offerCostFields.offerSelections = [];
      for (const ra of filteredRas) {
        this.offerCostFields.offerSelections.push(
          ...ra.offers.map(offer => ({
            isNgl: assignBooleanValue(offer.leaseDetails && offer.leaseDetails.selectedLease?.isNgl, false),
            offerId: assignStringValue(offer.getOfferId()),
            rgnlAltId: assignStringValue(ra.data.id),
            uid: offer.uid,
          })),
        );
      }
    }
  }

  setData = (offer: Offer, rgnlAlts?: RgnlAltModel[]) => {
    const offerFields: Pick<Offer, OfferFieldType> = { ...offer };
    this.offerFields = { ...offerFields };

    if (offer.nationalRyoDetails) {
      this.offerCostFields.amount = assignStringValue(offer.nationalRyoDetails.amount);
      this.offerCostFields.offerEnhancedTfsPnvs = offer.nationalRyoDetails.offerEnhancedTfsPnvs;
      this.offerCostFields.offerPnvs = assignStringValue(offer.nationalRyoDetails.offerPnvs);
      this.offerCostFields.offerTfsPnvs = offer.nationalRyoDetails.offerTfsPnvs;
      this.offerCostFields.ryoAmountCash = offer.nationalRyoDetails.ryoAmountCash;
      this.offerCostFields.ryoAmountPercent = offer.nationalRyoDetails.ryoAmountPercent;
      this.offerCostFields.isPercentSelected = assignBooleanValue(offer.nationalRyoDetails.isPercentSelected, true);

      if (offer.nationalRyoDetails.offerSelections) {
        this.offerCostFields.offerSelections = offer.nationalRyoDetails.offerSelections?.map(selection => {
          let uid = '';

          // find uid of offer if applicable
          if (rgnlAlts) {
            rgnlAlts.forEach(ra => {
              ra.offers.forEach(offer => {
                if (offer.getOfferId() === selection?.offerId) {
                  uid = offer.uid;
                }
              });
            });
          }

          return {
            isNgl: assignBooleanValue(selection?.isNgl, false),
            offerId: assignStringValue(selection?.offerId),
            rgnlAltId: assignStringValue(selection?.rgnlAltId),
            uid,
          };
        });
      }
    }
  };

  /**
   * Maps uid to offerSelections.
   * Used on load after all rgnlAlts and offers are processed.
   * Fyi, when adding new offers, the uid should be set there so no need to call this function then.
   * @param rgnlAlts
   */
  mapOfferSelectionUids = (rgnlAlts: RgnlAltModel[]) => {
    const allOffers = new Map<string, OfferModel>();
    rgnlAlts.forEach(item => {
      item.offers.forEach(offer => {
        allOffers.set(offer.getOfferId(), offer);
      });
    });

    this.offerCostFields.offerSelections.forEach(item => {
      const foundOffer = allOffers.get(item.offerId);
      if (foundOffer) {
        item.uid = foundOffer.uid;
      }
    });
  };

  updateOfferField = <T extends keyof OfferFields, V extends OfferFields[T]>(field: T, value: V) => {
    this.offerFields[field] = value;
  };

  updateOfferCostFields = <T extends keyof NationalRYOCostFields, V extends NationalRYOCostFields[T]>(field: T, value: V) => {
    this.offerCostFields[field] = value;
  };

  setRevAndId = (offer?: Offer) => {
    if (offer) {
      this.offerFields.id = offer.id;
      this.offerFields.rev = offer.rev;
      this.offerCostFields.amount = assignStringValue(offer.nationalRyoDetails?.amount);
      this.offerCostFields.offerPnvs = assignStringValue(offer.nationalRyoDetails?.offerPnvs);
      this.offerCostFields.ryoAmountCash = assignNumberValue(offer.nationalRyoDetails?.ryoAmountCash);
      this.offerCostFields.ryoAmountPercent = assignNumberValue(offer.nationalRyoDetails?.ryoAmountPercent);
    }
  };

  setPercentageActive = (value: boolean) => {
    this.offerCostFields.isPercentSelected = value;
  };

  hasError = () => {
    return this.nameError || this.penetrationRateError;
  };

  get nameError() {
    return !!validator(this.offerFields.name, { required: true });
  }

  get penetrationRateError() {
    return !!validator(this.offerFields.penetrationRate, { required: true });
  }
}

export default NationalRyoModel;
