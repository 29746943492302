import { observer } from 'mobx-react-lite';
import { OATLoadingBar, OATToastContainer, SideNav, SideNavLayout, SideNavLogo } from 'oat-common-ui';
import { ReactNode, useMemo } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { NavLink, useLocation } from 'react-router-dom';
import useStores from '../../stores/useStores';
import { getRegionalLink } from '../../utils/getRegionalLink';
import AnchorNav from './AnchorNav';

type Props = {
  children?: ReactNode;
};

const MainSideNav = ({ children }: Props) => {
  const {
    userInfoStore: { isLexus },
    globalStore: { showProgressBar },
  } = useStores();
  const { promiseInProgress } = usePromiseTracker();
  const { pathname } = useLocation();

  const isLowerEnv = useMemo(() => {
    return window.location.hostname.indexOf('nonprod') !== -1 || window.location.hostname.indexOf('localhost') !== -1;
  }, []);

  const sideNav = (
    <SideNav isProd={!isLowerEnv}>
      <SideNavLogo isLexus={isLexus()} />
      <nav>
        <p>FY Rollup</p>
        <nav>
          <AnchorNav to="/ngc/approved">Approved</AnchorNav>
          <AnchorNav to="/ngc/forecasted">Forecasted</AnchorNav>
          <AnchorNav to="/ngc/working">Working</AnchorNav>
          <AnchorNav to="/ngc/bosu">BOSU</AnchorNav>
          <AnchorNav to="/ngc/assumptions">Assumptions</AnchorNav>
          <AnchorNav to="/ngc/multiSeriesOffers">Multi-Series Offers</AnchorNav>
        </nav>

        <AnchorNav to="/dashboard" lessPadding paths={['/nationalPrograms', '/ipSettings']}>
          National Programs
        </AnchorNav>
        <AnchorNav to={getRegionalLink('dashboard')} toLegacy>
          Regional Review
        </AnchorNav>

        <p>Reports</p>
        <nav>
          <AnchorNav to="/reports">National Planning</AnchorNav>
          <AnchorNav to={getRegionalLink('reports/incentivePeriods')} toLegacy>
            Incentive Periods
          </AnchorNav>
        </nav>

        <p>Settings</p>
        <nav>
          <AnchorNav to="/settings/personal">Personal</AnchorNav>
          <AnchorNav to="/settings/pnvsThreshold">PNVS Threshold</AnchorNav>
          <AnchorNav to="/settings/seriesSorting">Series Sorting</AnchorNav>
          <AnchorNav to="/settings/seriesMapping">Series Mapping</AnchorNav>
          <AnchorNav to="/settings/stateAprCaps">State APR Caps</AnchorNav>
          <AnchorNav to="/settings/incentiveCalendar">Incentive Calendar</AnchorNav>
        </nav>

        <NavLink to="/help">Help</NavLink>
      </nav>
    </SideNav>
  );

  const isReportsPath = pathname.includes('reports');

  return (
    <>
      {!isReportsPath && <OATToastContainer />}
      {(promiseInProgress || showProgressBar) && <OATLoadingBar />}
      {isReportsPath ? (
        <>{children}</>
      ) : (
        <SideNavLayout sideNav={sideNav}>
          {/* content */}
          {children}
        </SideNavLayout>
      )}
    </>
  );
};

export default observer(MainSideNav);
