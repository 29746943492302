import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, ButtonGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import AccordionComponent from '../../../../components/Accordion/AccordionComponent';
import AccordionItemModel from '../../../../components/Accordion/models/AccordionItemModel';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromModelCodes from '../../../../components/Accordion/utils/createAccordionFromModelCodes';
import { Vehicle } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import SeriesProfileModel from '../../models/SeriesProfileModel';
import getIncludedModelCodes from './getIncludedModelCodes';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  ctaLabel?: string;
  disabled?: boolean;
  hideCtas?: boolean;
  id?: string;
  isExclusionsActive: boolean;
  labelExcluded: string;
  labelIncluded: string;
  onClose: () => void;
  onSubmit: (items: AccordionItemModel[], vehicles: Vehicle[]) => void;
  submitText?: string;
  seriesProfile: SeriesProfileModel;
  vehicles: Vehicle[];
  disabledVehicleIds?: string[],
}

const ExclusionsModal = ({
  ctaLabel,
  hideCtas = false,
  id,
  isExclusionsActive,
  labelExcluded,
  labelIncluded,
  onClose,
  onSubmit,
  submitText = 'Submit',
  seriesProfile,
  vehicles,
  disabledVehicleIds,
}: Props) => {
  const {
    nationalProgramsStore: { modelCodeMappings, seriesMapping },
  } = useStores();

  const accordion = useLocalObservable(
    () =>
      new AccordionModel(
        createAccordionFromModelCodes(
          getIncludedModelCodes(modelCodeMappings, vehicles),
          undefined,
          `${seriesProfile.data.modelYear} ${seriesProfile.data.series}`,
          vehicles,
          seriesMapping,
          true,
          disabledVehicleIds
        ),
      ),
  );

  const [exclusions, setExclusions] = useState(isExclusionsActive);
  const [modalSearchInput, setModalSearchInput] = useState('');
  const title = exclusions ? labelExcluded : labelIncluded;
  const hasAccordionItems = accordion.items.length > 0;

  const hasInclusions = !accordion.isAllSelected(false);

  const handleExclusions = () => {
    setExclusions(!exclusions);
    accordion.toggleReverse();
  };

  const handleSearchInput = (value: string) => {
    setModalSearchInput(value);
    accordion.setQuery(value);
    accordion.toggleExpandAll(true);
  };

  const handleSelectAll = () => {
    accordion.toggleAll(true);
  };

  const handleRemoveAll = () => {
    accordion.toggleAll(false);
  };

  const handleSubmit = () => {
    handleSearchInput('');
    // toggle back to inclusions mode for payload
    if (exclusions) {
      accordion.toggleReverse();
    }
    onSubmit(accordion.items, vehicles);
  };

  return (
    <Modal className={styles.width} isOpen onClose={onClose}>
      {/* Add styling for header */}
      <ModalHeader onClose={onClose}>{`Select ${title}`}</ModalHeader>
      <ModalBody>
        <div className={styles.searchExclusions}>
          <Input
            id="search-exclusions"
            isNational
            className={styles.searchExclusionsInput}
            placeholder="Search models"
            value={modalSearchInput}
            onChange={e => {
              handleSearchInput(e.target.value);
            }}
          />
          <OATIcon icon="search" size={16} colorTheme="blue" className={styles.searchIcon} />
        </div>
        <div className={styles.labelWrapper}>
          <OATIcon className={styles.icon} icon="switch" colorTheme="blue" size={14} onClick={handleExclusions} />
          <span className={styles.exclusion}>{exclusions ? 'Exclusions' : 'Inclusions'}</span>
        </div>
        <div className={styles.accordionWrapper}>
          {hasAccordionItems ? <AccordionComponent accordion={accordion} isDisabled={hideCtas} /> : <p className={styles.noModelsError}>No models found.</p>}
        </div>
      </ModalBody>
      {!hideCtas && (
        <ModalFooter className={styles.footerWrapper}>
          {!hasInclusions && (
            <span className={styles.errorText}>
              {`You cannot exclude all vehicles for the Series. Please ${exclusions ? 'deselect some exclusions' : 'select some inclusions'}.`}
            </span>
          )}
          <ButtonGroup>
            <Button id={`select-all-cta-${id}`} variant="text" className={styles.button} onClick={handleSelectAll}>{`Select All ${ctaLabel || title}`}</Button>
            <Button id={`remove-all-cta-${id}`} variant="text" className={styles.button} onClick={handleRemoveAll}>{`Remove All ${ctaLabel || title}`}</Button>
            <Button id={`default-modal-submit-${id}`} variant="primary" disabled={!hasInclusions} onClick={handleSubmit}>
              {submitText}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default observer(ExclusionsModal);
