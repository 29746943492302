import { DropdownItem } from 'oat-common-ui';
import { ICostShareSeriesProfile } from '../../../../stores/NationalCostShareStore';

export const handleInvalidDatesCheck = (
  addedSeriesOptions: DropdownItem[],
  costSharesBySeriesProfile: Map<string, ICostShareSeriesProfile>,
  updateHasInvalidDatesError: (val: boolean) => void,
) => {
  let dateErrorCount = 0;
  updateHasInvalidDatesError(false);

  for (const addedSeriesOption of addedSeriesOptions) {
    const costSharesBySeries = costSharesBySeriesProfile.get(addedSeriesOption.value);

    if (costSharesBySeries?.costShares.length) {
      const costShares = costSharesBySeries.costShares;

      for (const costShare of costShares) {
        if (costShare.hasDatesError) {
          dateErrorCount += 1;
        }
      }
    }
  }

  if (dateErrorCount > 0) {
    updateHasInvalidDatesError(true);
    return true;
  }
};
