import { OATLoadingBar } from 'oat-common-ui';
import { Route, RouteProps } from 'react-router-dom';
import { useGetUserInfoQuery } from '../../gql/generated';
import Error from '../../pages/ErrorPage';
import useStores from '../../stores/useStores';
import GetSeriesMappingData from '../GetSeriesMappingData';
import MainSideNav from '../MainSideNav';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { userInfoStore } = useStores();
  const { data, loading, error } = useGetUserInfoQuery();

  if (loading) {
    return <OATLoadingBar />;
  }

  if (error) {
    return <Error />;
  }

  if (data && data.userInfo) {
    userInfoStore.setUserInfo(data.userInfo, true);
  }

  return (
    <Route {...rest}>
      <GetSeriesMappingData>
        <MainSideNav>{children}</MainSideNav>
      </GetSeriesMappingData>
    </Route>
  );
};

export default PrivateRoute;
