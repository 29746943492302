import { AdminConstants } from 'oat-admin-common';
import { LocalTfsContract } from '../pages/NationalPrograms/models/offers/OfferFields';

const { OPTION_TYPE_NAMES } = AdminConstants;

export const HELP_URL = 'https://buyatoyota.atlassian.net/wiki/spaces/OATT/pages/188842022/TDA+How-To+Articles';

export enum Brand {
  TOYOTA = 'Toyota',
  LEXUS = 'Lexus',
}

export enum LdaCode {
  LWA = 'LWA',
  LSA = 'LSA',
  LEA = 'LEA',
  LCA = 'LCA',
}

export enum FYROLLUP {
  APPROVED = 'approved',
  FORECASTED = 'forecasted',
  WORKING = 'working',
}

export enum SeriesTitle {
  STOCK = 'Dealer Stock',
  SALES = 'Sales Volume',
  PNVS = 'PNVS',
  TOTAL = 'Total',
}

export enum SeriesMonthTotalsTitle {
  SALES = 'sales',
  PNVS = 'pnvs',
  TOTALCOST = 'totalCost',
  STOCK = 'stock',
}

export type SeriesTotals = { [month: string]: number };

// Used for series sorting on initial save
export const INITIAL_REV_ID = 'initialRevId';

export enum UPLOAD_TYPE {
  BOSU = 'bosu-settings',
  BOSU_IP = 'bosu-ip',
  STATE_APR_CAPS = 'state-apr-cap',
  OFRNG_UPLOAD = 'offering-upload',
}

export const MULTIPLE_RATES_APPLIED = 'Multiple Rates Applied';
export const MULTIPLE_RCF_APPLIED = 'Multiple RCF Applied';
export const RCF_INITIAL_VALUE = 0.000001;

export enum FYROLLUP_BUTTON_TEXT {
  EDIT = 'Edit',
  SAVE = 'Save Changes',
}

export enum TFS_LABEL {
  TOYOTA = 'TFS',
  LEXUS = 'LFS',
}

export enum TMS_LABEL {
  TOYOTA = 'TMS',
  LEXUS = 'LMS',
}

export enum OfferingUploadOptions {
  MODEL_CODES = 'Model Codes',
  PRE_NGL_RESIDUAL_VALUE = 'Pre-NGL Residual Values',
  POST_NGL_RESIDUAL_VALUE = 'Post-NGL Residual Values',
  REGIONAL_APR_RATES = 'Regional APR Rates',
  BOSU_FILE = 'BOSU File',
  ENHANCED_STANDARD_RCFS = 'Enhanced Standard RCFs',
}

export const OfferingStatus = {
  PUBLISHED: 'Published',
  DRAFT: 'Draft',
  PUBLISHE_IN_PROGRESS: 'Publish In Progress',
} as const;

export const PUBLISHED_ATC_DATE_FORMAT = 'MM-dd-yyyy';

// For APR Card
export const VIEW_ALL_RATES = 'View All Rates';

export const defaultTiersTerms = {
  tiers: ['1+', '1', '2', '3'],
  terms: [24, 36, 48, 60, 72],
};

export const defaultTfsContracts: LocalTfsContract[] = [
  {
    contractType: 'APR',
    isSubvented: true,
    tierTerms: [],
    termsList: defaultTiersTerms.terms,
    tiersList: defaultTiersTerms.tiers,
  },
  {
    contractType: 'APR',
    isSubvented: false,
    tierTerms: [],
    termsList: defaultTiersTerms.terms,
    tiersList: defaultTiersTerms.tiers,
  },
  {
    contractType: 'Lease',
    isSubvented: true,
    tierTerms: [],
    termsList: defaultTiersTerms.terms,
    tiersList: defaultTiersTerms.tiers,
  },
  {
    contractType: 'Lease',
    isSubvented: false,
    tierTerms: [],
    termsList: defaultTiersTerms.terms,
    tiersList: defaultTiersTerms.tiers,
  },
];

export enum SubventionMethods {
  BLENDED = 'blended',
  TIER = 'tier',
}

export enum REPORT_VALUE {
  APPROVED = 'approved',
  FORECASTED = 'forecasted',
  WORKING = 'working',
  FY_INCENTIVE_PNVS = 'FyIncentivePnvs',
  FY_MODELYEAR_ALLOCATION = 'FyModelYearAllocation',
  EXECUTIVE_OFFER_SUMMARY = 'ExecutiveOfferSummary',
  OFFER_SETUP = 'OfferSetup',
  NATIONAL_GUIDANCE = 'NationalGuidance',
  NATIONAL_OFFER_DETAILS = 'NationalOfferDetails',
  HISTORICAL_PROGRAM_TRACKER = 'HistoricalProgramTracker',
  SUMMARY = 'Summary',
}

export const FYReports = [
  { label: 'Approved', value: REPORT_VALUE.APPROVED },
  { label: 'Forecasted', value: REPORT_VALUE.FORECASTED },
  { label: 'Working', value: REPORT_VALUE.WORKING },
  { label: 'Incentive PNVS Input', value: REPORT_VALUE.FY_INCENTIVE_PNVS },
  { label: 'Model Year Allocation', value: REPORT_VALUE.FY_MODELYEAR_ALLOCATION },
];

export const NPReports = [
  { label: 'Executive Offer Summary', value: REPORT_VALUE.EXECUTIVE_OFFER_SUMMARY },
  { label: 'Offer Setup', value: REPORT_VALUE.OFFER_SETUP },
  { label: 'National Guidance', value: REPORT_VALUE.NATIONAL_GUIDANCE },
  { label: 'National Offer Details', value: REPORT_VALUE.NATIONAL_OFFER_DETAILS },
  { label: 'Historical Program Tracker', value: REPORT_VALUE.HISTORICAL_PROGRAM_TRACKER },
  { label: 'Offer Summary', value: REPORT_VALUE.SUMMARY },
];

export const getTargetedAudienceCollageGraduateText = (brand: Brand.TOYOTA | Brand.LEXUS): string =>
  `Currently enrolled in a graduate degree program from an eligible school (as defined below), or enrolled in or be a graduate from an eligible school and provide proof of graduation (showing an upcoming graduation within six months OR a recent graduation within two calendar years from the date of signing your vehicle lease or finance contract) from one of the following eligible schools located in the 50 United States, Puerto Rico, or U.S. Virgin Islands: (a) an accredited four-year college, university or registered nursing program; OR (b) an accredited two-year college associate degree or an undergraduate academic degree as awarded by community colleges, junior colleges, technical colleges and universities; OR (c) an accredited graduate school. In all cases, the student will have two calendar years from receipt of their degree to take advantage of the program. Must provide proof of qualification via an ID.me account and complete the verification process at a participating ${brand} dealer.`;

export const getTargetedAudienceMilitaryText = (brand: Brand.TOYOTA | Brand.LEXUS): string =>
  `(1) In current active duty status in the U.S. military (Navy, Army, Air Force, Marines, National Guard, Coast Guard and active Reserve) OR a U.S. military inactive reserve (i.e., Ready Reserve) that is part of the individual Ready Reserve, Selected Reserve and Inactive National Guard; OR a retiree of the U.S. military, OR a U.S. military veteran within two years of their discharge date; OR a spouse/ surviving spouse and dependents of an eligible U.S. Military personnel including Gold Star Family Members; AND (2) provide verifiable proof of military status or active service; or retired via an ID.me account and complete the verification process at a participating ${brand} dealer; (3) receive credit approval from and execute a finance or lease contract through ${brand} Financial Services at participating ${brand} dealers.`;

export const hasTargetedAudience = [
  process.env.REACT_APP_FEATURE_OR_5454_PHASE_2 === 'true' ? OPTION_TYPE_NAMES.COLLEGE.valueOf() : OPTION_TYPE_NAMES.COLLEGE_GRADUATE.valueOf(),
  OPTION_TYPE_NAMES.MILITARY.valueOf(),
  OPTION_TYPE_NAMES.LEASE_LOYALTY.valueOf(),
  OPTION_TYPE_NAMES.TARGETED_CERTIFICATE.valueOf(),
  OPTION_TYPE_NAMES.CAMPAIGN.valueOf(),
  OPTION_TYPE_NAMES.LOYALTY.valueOf(),
  OPTION_TYPE_NAMES.CONQUEST.valueOf(),
];

export const hasTargetedAudienceRequired = [
  process.env.REACT_APP_FEATURE_OR_5454_PHASE_2 === 'true' ? OPTION_TYPE_NAMES.COLLEGE.valueOf() : OPTION_TYPE_NAMES.COLLEGE_GRADUATE.valueOf(), //
  OPTION_TYPE_NAMES.MILITARY.valueOf(),
];

export const hideManageCompatibility = [OPTION_TYPE_NAMES.ADDITIONAL_RYO.valueOf(), OPTION_TYPE_NAMES.RYO_EARNINGS.valueOf(), OPTION_TYPE_NAMES.LUMPSUM_RYO.valueOf()];

export const overlapDatesText = 'Overlapping Dates. Please correct fields in red.';
export const invalidDatesText = 'Invalid Dates. Please correct fields in red.';
export const overlapAndInvalidDatesText = 'Overlapping and invalid dates. Please correct fields in red.';

// FEATURE FLAGS
export const FEATURE_OR_3325 = process.env.REACT_APP_FEATURE_OR_3325 === 'true';
export const FEATURE_OR_2477 = process.env.REACT_APP_FEATURE_OR_2477 === 'true';
export const FEATURE_OR_2816 = process.env.REACT_APP_FEATURE_OR_2816 === 'true';
export const FEATURE_OR_4767 = process.env.REACT_APP_FEATURE_OR_4767 === 'true';
export const FEATURE_OR_4154 = process.env.REACT_APP_FEATURE_OR_4154 === 'true';
export const FEATURE_OR_5454_PHASE_2 = process.env.REACT_APP_FEATURE_OR_5454_PHASE_2 === 'true';
export const FEATURE_UNPUBLISH = process.env.REACT_APP_FEATURE_UNPUBLISH === 'true';
export const FEATURE_OR_4641 = process.env.REACT_APP_FEATURE_OR_4641 === 'true';
