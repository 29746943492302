import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { SubventionMethods } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import LeaseSetupModel from '../../../models/offersSetup/LeaseSetupModel';
import CreditTiers from './CreditTiers';

import SeriesProfileModel from '../../../models/SeriesProfileModel';
import getSortedTiers from '../../../utils/getSortedTiers';
import styles from './styles.module.scss';

interface Props {
  leaseSetupDetails: LeaseSetupModel;
  seriesProfile: SeriesProfileModel;
  saveLeaseSetup: () => void;
}

function LeaseOfferSetup({ leaseSetupDetails, saveLeaseSetup, seriesProfile }: Props) {
  const {
    nationalProgramsStore: { isOfferingPublished },
  } = useStores();
  const [selectedOption, setSelectedOption] = useState<string>(leaseSetupDetails.fields.subventionMethod);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.currentTarget.value);
    leaseSetupDetails.updateField('subventionMethod', event.currentTarget.value);
  };

  const selectedTiers = getSortedTiers(leaseSetupDetails.fields.tiers.filter(tier => tier.isSelected));

  return (
    <fieldset className={styles.leaseSetup} disabled={seriesProfile.isConfirmed || isOfferingPublished}>
      <div className={styles.subventionMethod}>
        <h5 className={styles.subventionMethodLabel}>Subvention method</h5>

        <label className={styles.subventionMethodOption} htmlFor={`${leaseSetupDetails.uid}-blended`}>
          Blended
          <input
            checked={selectedOption === SubventionMethods.BLENDED}
            id={`${leaseSetupDetails.uid}-blended`}
            onChange={handleOptionChange}
            type="radio"
            disabled={seriesProfile.isConfirmed}
            value={SubventionMethods.BLENDED}
          />
        </label>
        <label className={styles.subventionMethodOption} htmlFor={`${leaseSetupDetails.uid}-tier`}>
          Tier
          <input
            checked={selectedOption === SubventionMethods.TIER}
            id={`${leaseSetupDetails.uid}-tier`}
            onChange={handleOptionChange}
            type="radio"
            value={SubventionMethods.TIER}
          />
        </label>
      </div>
      {selectedOption === SubventionMethods.BLENDED ? (
        <CreditTiers
          disabled={seriesProfile.isConfirmed}
          tiers={leaseSetupDetails.fields.tiers}
          tier={selectedTiers[selectedTiers.length - 1].tier}
          updateTier={tier => {
            leaseSetupDetails.updateTier(tier);
            saveLeaseSetup();
          }}
        />
      ) : (
        <>Tier</>
      )}
    </fieldset>
  );
}

export default observer(LeaseOfferSetup);
