import { DropdownItem } from 'oat-common-ui';
import { CostShareSeriesProfileInput } from '../../../../gql/generated';
import { ICostShareSeriesProfile } from '../../../../stores/NationalCostShareStore';
import { sortCostSharesByType } from './sortCostSharesByType';

export const createSeriesProfilesParam = (addedSeriesOptions: DropdownItem[], costSharesBySeriesProfile: Map<string, ICostShareSeriesProfile>): CostShareSeriesProfileInput[] => {
  const seriesProfilesParam: CostShareSeriesProfileInput[] = [];

  for (const addedSeriesOption of addedSeriesOptions) {
    const costSharesBySp = costSharesBySeriesProfile.get(addedSeriesOption.value);

    if (costSharesBySp) {
      const sp: CostShareSeriesProfileInput = {
        id: addedSeriesOption.value,
        created: costSharesBySp.created,
        costShares: sortCostSharesByType(costSharesBySp.costShares).map(c => {
          const {
            id,
            fields: { optionTypeName, value, startDate, endDate },
          } = c;

          return {
            id,
            optionTypeName,
            value: Number(value),
            startDate,
            endDate,
          };
        }),
      };

      seriesProfilesParam.push(sp);
    }
  }

  return seriesProfilesParam;
};
